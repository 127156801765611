body {
  margin: 0;
}

.top-pane {
  background-color: hsl(225, 6%, 25%);
}

.pane {
  height: 50vh;
  display: flex;
}

.editor-container {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  padding: .5rem;
  max-width: 30vw;
  background-color: hsl(225, 6%, 25%);
}

.editor-title {
  display: flex;
  justify-content: space-between;
  background-color: hsl(225, 6%, 13%);
  color: white;
  padding: .5rem .5rem .5rem 1rem;
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
}

.code-mirror-wrapper {
  flex-grow: 1;
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
  overflow: hidden;
}

.main {
  display: flex;
  height: calc(100vh - 50px);
}

.questions {
  width: 20vw;
  padding: 5px;
  background-color:hsl(103, 7%, 19%);
  color: white;
}

.code {
  width: 80vw;
}

.question {
  display: flex;
  align-items: center;
}

.output-area {
  padding: 10px;
}

.heading {
  text-align: center;
  color: white;
  padding: 1px;
  font-size: 12px;
  background-color:hsl(103, 7%, 19%);
}
